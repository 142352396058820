import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../../components/layoutDoc";
import Seo from "../../components/seo";

function OfficialEmail() {
    return (
        <Layout page="Introduction" pageInner="Official">
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Introduction" />
            <Container fluid='lg' className="py-md-5 py-4">
                <Row className="justify-content-center mb-lg-5 mb-3">
                    <Col lg={12} className="mb-3">
                        <div className="section-title text-center">
                            <h2>Official Email</h2>
                        </div>
                        <p>HedgePay Sdn Bhd (“HedgePay”) hereby informs you that our company and its employees are meticulous in our use of e-mails for the purpose of corresponding with you. We adhere to stringent guidelines in terms of the use of our e-mails and the use of e-mails except for the authorized e-mails to communicate with you is prohibited therein. The authorized e-mail addresses previously referred to are limited to the e-mail addresses provided on this page.</p>
                        <p>HedgePay as a corporate conscious of the accountability to you and the society in general is willing to take every step necessary to establish and enhance your protection on cyberspace. We thoroughly understand the need for your protection from any frauds from individuals and/or entities who are using your e-mail addresses to victimize you by their illegal activities.</p>
                        <p>As you may be aware there are frauds involving phishing e-mails sent by e-mail addresses similar to that of the entity (ex. mike@hegepay.org) that you are obtaining services of, in accordance with the terms of services of such entity. Being mindful of the responsibilities entrusted with us by you, we urge you to ignore any such e-mail sent to you barring the e-mail addresses provided on this page.</p>
                        <p>You can reach us by using this link to our “Report a Fraud” form for any complaints. You are further advised that the e-mail addresses mentioned are strictly for the purpose of us reaching out to you and all your inquiries shall be made via our “Contact Us” form.</p>

                        <p className='text-white mb-2' style={{opacity: '1'}}>HedgePay official emails:</p>
                        <ol style={{marginLeft: '-15px'}} type="i">
                            <li className='mb-1'><span className='ps-2'>support@hedgepay.org</span></li>
                            <li className='mb-1'><span className='ps-2'>ceo@hedgepay.org</span></li>
                            <li className='mb-1'><span className='ps-2'>operation@hedgepay.org</span></li>
                            <li className='mb-1'><span className='ps-2'>marketing@hedgepay.org</span></li>
                            <li className='mb-1'><span className='ps-2'>events@hedgepay.org</span></li>
                        </ol>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}

export default OfficialEmail;
